import { useEffect, useState } from "react";

import {
  Alert,
  AlertTitle,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { ChooserProps } from ".";
import {
  cloudEnabled,
  downloadRemoteFile,
  listRemoteFiles,
  RemoteFile,
} from "../../../services/fileman";

export default function CloudFileList(props: ChooserProps) {
  const [listingRemoteFiles, setListingRemoteFiles] = useState(false);
  const [remoteFiles, setRemoteFiles] = useState<RemoteFile[]>([]);

  useEffect(() => {
    if (!cloudEnabled()) return;

    setListingRemoteFiles(true);

    listRemoteFiles()
      .then((files) => {
        setRemoteFiles(files);
      })
      .catch((e) => {
        // TODO(kevin): Better error handling
        console.log("something went wrong", e);
      })
      .finally(() => {
        setListingRemoteFiles(false);
      });
  }, [props]);

  const downloadFile = async (path: string) => {
    props.onLoading();
    const fileContent = await downloadRemoteFile(path);
    props.onChosen(fileContent);
  }

  if (!cloudEnabled()) {
    return (
      <Alert severity="info">
        <AlertTitle>Awwmap Cloud is disabled</AlertTitle>
        This version of Awwmap does not support the cloud feature.
      </Alert>
    );
  }

  if (listingRemoteFiles) {
    return <CircularProgress />;
  }

  return (
    <List>
      {remoteFiles.map((rf) => (
        <ListItem disablePadding key={rf.path}>
          {/* TODO(kevin): Implement downloading */}
          <ListItemButton
            onClick={() => downloadFile(rf.path)}
          >
            <ListItemIcon>
              <InsertDriveFileIcon />
            </ListItemIcon>
            <ListItemText primary={rf.name} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}
