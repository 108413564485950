import React, { useMemo, useState } from "react";

import "leaflet/dist/leaflet.css";
import styles from "./RootMap.module.css";

import markerIconUrl from "leaflet/dist/images/marker-icon.png";
import markerRetinaUrl from "leaflet/dist/images/marker-icon-2x.png";
import markerShadowUrl from "leaflet/dist/images/marker-shadow.png";

import { Feature, FeatureCollection } from "geojson";
import L, { LatLngTuple } from "leaflet";
import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";
import Toolbar from "../toolbar/Toolbar";
import FeatureEditDialog from "../feature-edit-dialog/FeatureEditDialog";

const icon = defaultMarkerIcon();

export default function RootMap(props: Props) {
  // react-leaflet only updates the map children if they get new unique keys,
  // so we generate a new random number every time the incoming geoFile changes
  const geoKey = useMemo(() => Math.random(), [props.geoFile]);
  const [editing, setEditing] = useState<Feature | undefined>();

  const handleFeatureClick = (feature: Feature) => {
    setEditing(feature);
  };

  const handleCancelEditor = () => {
    setEditing(undefined);
  };

  const handleSubmitEditor = () => {
    setEditing(undefined);
  };

  return (
    <React.Fragment>
      <MapContainer className={styles.rootmap} center={props.center} zoom={12}>
        <TileLayer
          url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
          maxZoom={18}
          minZoom={3}
        />
        <GeoJSON
          key={geoKey}
          data={props.geoFile}
          onEachFeature={(feature, layer) => {
            layer.on("click", () => handleFeatureClick(feature));
          }}
          pointToLayer={(point, coords) => {
            return L.marker(coords, {
              icon,
            });
          }}
        />
        <Toolbar
          onClickOpen={props.onClickOpen}
          onClickSave={props.onClickSave}
        />
      </MapContainer>
      <FeatureEditDialog
        open={!!editing}
        feature={editing}
        onCancel={handleCancelEditor}
        onSubmit={handleSubmitEditor}
      />
    </React.Fragment>
  );
}

export interface Props {
  readonly center: LatLngTuple;
  readonly geoFile: FeatureCollection;
  readonly onClickOpen: () => void;
  readonly onClickSave: () => void;
}

function defaultMarkerIcon() {
  return L.icon({
    iconUrl: markerIconUrl,
    iconRetinaUrl: markerRetinaUrl,
    shadowUrl: markerShadowUrl,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41],
  });
}
