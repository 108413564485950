import { Button, SvgIcon, SvgIconProps } from "@mui/material";
import { useDropboxChooser } from "use-dropbox-chooser";
import { ChooserProps } from ".";

const appKey = process.env.REACT_APP_DROPBOX_KEY;

export default function DropBoxButton(props: ChooserProps) {
  const dropboxState = useDropboxChooser({
    appKey: appKey,
    chooserOptions: { linkType: "direct" },
    onSelected: async (files) => {
      if (files && files.length === 1) {
        props.onLoading();
        const url = files[0].link;
        const response = await fetch(url);
        const textContent = await response.text();
        props.onChosen(textContent);
      }
    },
  });

  return (
    <Button
      variant="outlined"
      startIcon={<DropBoxIcon />}
      onClick={dropboxState.open}
      disabled={!appKey || props.disabled || dropboxState.isOpen}
    >
      Dropbox
    </Button>
  );
}

function DropBoxIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g transform="scale(0.75 0.75)" fill="#0061fe">
        <path
          clipRule="evenodd"
          d="m10.0632 6.5-5.9382 3.6362 5.9382 3.6361-5.9382 3.6362 5.9382 3.6361 5.9383-3.6361 5.9382 3.6361 5.9382-3.6361-5.9382-3.6362 5.9382-3.586-5.9382-3.6863-5.9382 3.6362zm5.9383 3.6362 5.9382 3.6361-5.9382 3.6362-5.9383-3.6362z"
          fillRule="evenodd"
        />
        <path d="m10.0632 22.8638 5.9383-3.6361 2.9691 1.8181 2.9691 1.818-5.9382 3.6362z" />
      </g>
    </SvgIcon>
  );
}
