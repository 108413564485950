import React from "react";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import SaveIcon from '@mui/icons-material/Save';

import styles from "./Toolbar.module.css";

export default function Toolbar(props: Props) {
  const stopPropagation = (e: React.BaseSyntheticEvent) => {
    // Prevent double-clicking from zooming in
    e.stopPropagation();
  };

  return (
    <div className="leaflet-bottom leaflet-left">
      <div className="leaflet-control">
        <div className={styles.toolbar}>
          <a
            href="#"
            title="Open"
            role="button"
            aria-label="Open local file"
            aria-disabled="false"
            onClick={props.onClickOpen}
            onDoubleClickCapture={stopPropagation}
          >
            <FolderOpenIcon fontSize="large" />
          </a>
          <a
            href="#"
            title="Save"
            role="button"
            aria-label="Save local file"
            aria-disabled="false"
            onClick={props.onClickSave}
            onDoubleClickCapture={stopPropagation}
          >
            <SaveIcon fontSize="large" />
          </a>
        </div>
      </div>
    </div>
  );
}

export interface Props {
  onClickOpen: () => void;
  onClickSave: () => void;
}
