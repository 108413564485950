import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { Feature } from "geojson";

export default function FeatureEditDialog(props: Props) {
  const [formValues, updateFormValue, addFormValue] = useFormValues(
    props.feature
  );

  const handleSubmit = () => {
    if (props.feature) {
      // TODO(kevin): Check for duplicate keys
      props.feature.properties = Object.fromEntries(
        formValues.filter((fv) => !fv.deleted).map((fv) => [fv.key, fv.value])
      );
    }
    props.onSubmit();
  };

  const handleAddClick = () => {
    addFormValue();
  };

  return (
    <Dialog open={props.open} onClose={props.onCancel}>
      <DialogTitle>Edit feature</DialogTitle>
      <DialogContent>
        <Stack>{renderInputs(formValues, updateFormValue)}</Stack>
        {/* TODO(kevin): Better layout of "add" button */}
        <IconButton onClick={handleAddClick}>
          <AddIcon />
        </IconButton>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel}>Cancel</Button>
        <Button onClick={handleSubmit}>Done</Button>
      </DialogActions>
    </Dialog>
  );
}

export interface Props {
  readonly open: boolean;
  readonly feature?: Feature;
  readonly onCancel: () => void;
  readonly onSubmit: () => void;
}

interface FormValue {
  readonly id: number;
  readonly key: string;
  readonly value: any;
  readonly deleted: boolean;
}

function useFormValues(
  feature?: Feature
): [FormValue[], (id: number, updates: Partial<FormValue>) => void, () => void] {
  const [formValues, setFormValues] = useState(initialFormValues(feature));

  useEffect(() => setFormValues(initialFormValues(feature)), [feature]);

  const updateFormValue = (id: number, updates: Partial<FormValue>) => {
    setFormValues(
      formValues.map((v) => (v.id === id ? { ...v, ...updates } : v))
    );
  };

  const addFormValue = () => {
    setFormValues([
      ...formValues,
      {
        id: formValues.length,
        key: "",
        value: "",
        deleted: false,
      },
    ]);
  };

  return [formValues, updateFormValue, addFormValue];
}

function initialFormValues(feature?: Feature): FormValue[] {
  if (!feature || !feature.properties) return [];

  return Object.entries(feature.properties).map(([key, value], index) => ({
    id: index,
    key,
    value,
    deleted: false,
  }));
}

function renderInputs(
  formValues: FormValue[],
  updateFormValue: (id: number, updates: Partial<FormValue>) => void
) {
  const handleKeyChange = (id: number, newKey: string) => {
    updateFormValue(id, { key: newKey });
  };

  const handleValueChange = (id: number, newValue: string) => {
    updateFormValue(id, { value: newValue });
  };

  const handleDelete = (id: number) => {
    updateFormValue(id, { deleted: true });
  };

  return formValues
    .filter((fv) => !fv.deleted)
    .map((formValue) => (
      <Box key={formValue.id} display="flex">
        <Box sx={{ flexGrow: 1 }} p={1}>
          <TextField
            margin="none"
            type="text"
            variant="outlined"
            size="small"
            autoComplete="off"
            value={formValue.key}
            onChange={(e) => handleKeyChange(formValue.id, e.target.value)}
            fullWidth
          />
        </Box>
        <Box sx={{ flexGrow: 1 }} p={1}>
          <TextField
            margin="none"
            type="text"
            variant="outlined"
            size="small"
            autoComplete="off"
            value={formValue.value}
            onChange={(e) => handleValueChange(formValue.id, e.target.value)}
            fullWidth
          />
        </Box>
        <Box p={1}>
          <IconButton onClick={() => handleDelete(formValue.id)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
    ));
}
