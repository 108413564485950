import { Button } from "@mui/material";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { ChooserProps } from ".";

export default function LocalFileButton(props: ChooserProps) {
  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      props.onLoading();
      const file = e.target.files[0];
      const textContent = await file.text();
      props.onChosen(textContent);
    }
  };

  return (
    <Button
      variant="outlined"
      component="label"
      startIcon={<FolderOpenIcon />}
      disabled={props.disabled}
    >
      Local file
      <input hidden accept=".geojson" type="file" onChange={handleChange} />
    </Button>
  );
}
