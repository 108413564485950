const USE_REMOTE_SERVER = process.env.REACT_APP_USE_REMOTE_SERVER;
const REMOTE_SERVER_ROOT = process.env.REACT_APP_REMOTE_SERVER_ROOT ?? "http://localhost:5000";
const API_BASE = `${REMOTE_SERVER_ROOT}/api`;
const GEOFILES_BASE = `${REMOTE_SERVER_ROOT}/geofiles`;

export function cloudEnabled(): boolean {
  return !!USE_REMOTE_SERVER;
}

export async function listRemoteFiles(): Promise<RemoteFile[]> {
  if (!USE_REMOTE_SERVER) return [];

  const response = await fetch(`${API_BASE}/files`);
  const json = await response.json();
  return json.items;
}

export async function downloadRemoteFile(path: string): Promise<string> {
  if (!USE_REMOTE_SERVER) return "";
  const response = await fetch(`${GEOFILES_BASE}/${path}`);
  return await response.text();
}

export interface RemoteFile {
  readonly name: string;
  readonly path: string;
}
