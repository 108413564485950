import React, { useState } from "react";

import styles from "./FileDialog.module.css";

import {
  Alert,
  AlertTitle,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
} from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { DropBoxButton, LocalFileButton } from "./choosers";
import CloudFileList from "./choosers/CloudFileList";

export default function FileDialog(props: Props) {
  const [loading, setLoading] = useState(false);

  const handleChosen = (fileContent: string) => {
    setLoading(false);
    props.onSubmit(fileContent);
  };

  const handleLoading = () => {
    setLoading(true);
  };

  return (
    <React.Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog open={props.open} onClose={props.onCancel}>
        <DialogTitle>Open GeoJSON file</DialogTitle>
        <DialogContent>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            spacing={1}
          >
            <Alert severity="warning">
              <AlertTitle>Awwmap is a work in-progress</AlertTitle>
              Right now it's just a skeleton of a project. Use at own risk!
            </Alert>
            <LocalFileButton
              disabled={loading}
              onChosen={handleChosen}
              onLoading={handleLoading}
            />
            <DropBoxButton
              disabled={loading}
              onChosen={handleChosen}
              onLoading={handleLoading}
            />
            <Divider>
              <CloudDownloadIcon className={styles.dividerIcon} />
              <span className={styles.dividerText}>Awwmap Cloud</span>
            </Divider>
            <CloudFileList
              disabled={loading}
              onChosen={handleChosen}
              onLoading={handleLoading}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export interface Props {
  readonly open: boolean;
  readonly onCancel: () => void;
  readonly onSubmit: (textContent: string) => void;
}
