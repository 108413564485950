import React, { useState } from "react";

import { FeatureCollection } from "geojson";
import { LatLngTuple } from "leaflet";

import { latLongToLongLat } from "../../util/coordinates";
import RootMap from "../map/RootMap";
import FileDialog from "../file-dialog/FileDialog";

export default function App() {
  const [isFileDialogOpen, setFileDialogOpen] = useState(true);
  const [geoFile, setGeoFile] = useState(defaultGeoFile);

  const handleFileDialogSubmit = (txt: string) => {
    const geoData = parseGeoFile(txt);
    setGeoFile(geoData);
    setFileDialogOpen(false);
  };

  const handleFileDialogCancel = () => {
    setFileDialogOpen(false);
  };

  const handleClickOpen = () => {
    setFileDialogOpen(true);
  };

  const handleClickSave = () => {
    if (!geoFile) return;

    // TODO(kevin): Replace with less hacky file saving mechanism
    const pom = document.createElement("a");
    pom.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," +
        encodeURIComponent(JSON.stringify(geoFile))
    );
    pom.setAttribute("download", "my.geojson");
    pom.click();
  };

  return (
    <React.Fragment>
      <RootMap
        geoFile={geoFile}
        center={greenPointLighthouse}
        onClickOpen={handleClickOpen}
        onClickSave={handleClickSave}
      />
      <FileDialog
        open={isFileDialogOpen}
        onSubmit={handleFileDialogSubmit}
        onCancel={handleFileDialogCancel}
      />
    </React.Fragment>
  );
}

const greenPointLighthouse: LatLngTuple = [-33.90141330453546, 18.399866069213143];

const defaultGeoFile: FeatureCollection = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: latLongToLongLat(greenPointLighthouse),
      },
      properties: {
        someprop: "0.34523",
      },
    },
  ],
};

function parseGeoFile(geoFile: string): FeatureCollection {
  // TODO(kevin): Validate GeoJSON
  return JSON.parse(geoFile) as FeatureCollection;
}
